import { lazy, Suspense } from 'react'
import { createBrowserRouter } from 'react-router-dom'

import Loading from 'components/common/Loading/Loading'
import paths from 'routes/paths'
import ProtectedRoutes from 'routes/ProtectedRoutes'
import UnProtectedRoutes from 'routes/UnProtectedRoutes'
const Register = lazy(() => import('pages/Register/Register'))
const Workspace = lazy(() => import('pages/Workspace/Workspace'))
const Home = lazy(() => import('pages/Home/Home'))
const DebugPage = lazy(() => import('pages/DebugPage/DebugPage'))
const DebugPage2 = lazy(() => import('pages/DebugPage/DebugPage'))
const SetPassword = lazy(
  () => import('components/ForgotPassword/setNewPassword'),
)
const Login = lazy(() => import('components/Login/Login'))
const Confirmation = lazy(() => import('components/Confirmation/Confirmation'))

const SetNewPassword = lazy(
  () => import('components/ForgotPassword/ForgotPassword'),
)
const SetFirstPassword = lazy(
  () => import('components/SetPassword/SetPassword'),
)
const InviteUserSignup = lazy(
  () => import('pages/InviteUserSignup/InviteUserSignup'),
)
const PaymentSuccess = lazy(() => import('pages/Payment/PaymentSuccess'))
const PaymentFailed = lazy(() => import('pages/Payment/PaymentFailure'))
const SubscriptionDetails = lazy(
  () => import('pages/Payment/SubscriptionDetails'),
)
const WallBoardFullScreen = lazy(
  () => import('pages/Wallbaord/WallboardFullScreen'),
)

interface Routes {
  path: string
  element: React.ReactNode
  children?: []
}

const getRouteElement = (
  Component: React.ElementType,
  isProtected: boolean,
): React.ReactNode => (
  <Suspense fallback={<Loading />}>
    {isProtected ? (
      <ProtectedRoutes>
        <Component />
      </ProtectedRoutes>
    ) : (
      <UnProtectedRoutes>
        <Component />
      </UnProtectedRoutes>
    )}
  </Suspense>
)

const routes: Routes[] = [
  { path: paths.REGISTER, element: getRouteElement(Register, false) },
  { path: paths.WORKSPACE, element: getRouteElement(Workspace, true) },
  { path: paths.HOME, element: getRouteElement(Home, true) },
  { path: paths.DEBUG, element: getRouteElement(DebugPage, true) },
  { path: paths.DEBUG2, element: getRouteElement(DebugPage2, true) },
  {
    path: paths.FORGOTPASSWORD,
    element: getRouteElement(SetPassword, false),
  },
  {
    path: paths.LOGIN,
    element: getRouteElement(Login, false),
  },
  {
    path: paths.VERIFYEMAIL,
    element: getRouteElement(Confirmation, false),
  },
  {
    path: paths.SETPASSWORD,
    element: getRouteElement(SetFirstPassword, true),
  },
  {
    path: paths.PASSWORDEMAIL,
    element: getRouteElement(SetNewPassword, false),
  },
  {
    path: paths.INVITEUSERSIGNUP,
    element: getRouteElement(InviteUserSignup, false),
  },
  {
    path: paths.PAYMENTSUCCESS,
    element: getRouteElement(PaymentSuccess, true),
  },
  {
    path: paths.PAYMENTFAILED,
    element: getRouteElement(PaymentFailed, true),
  },
  {
    path: paths.SUBSCRIPTIONDETAILS,
    element: getRouteElement(SubscriptionDetails, true),
  },
  {
    path: paths.WALLBOARDSHARE,
    element: getRouteElement(WallBoardFullScreen, false),
  },
]

export default createBrowserRouter(routes)
