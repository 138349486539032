import { createAsyncThunk } from '@reduxjs/toolkit'
import { ATTRIBUTION_SELECTION_OPTIONS } from 'constants/dropdowns.ts'
import {
  calculatePreviousPeriod,
  formatDateToYYYYMMDD,
} from 'helpers/dateFilterUtils'
import httpClient from 'http/httpClient'
import { RootState } from 'store'
import {
  ActivityAdsCrmData,
  FetchAdsCrmDataParams,
} from 'types/activityAdsCrmData.model'

interface ApiResponse {
  count: number
  data: ActivityAdsCrmData[]
}

export const fetchAdsCrmData = createAsyncThunk<
  ActivityAdsCrmData[],
  FetchAdsCrmDataParams,
  { state: RootState; rejectValue: string }
>('adsCrmData/fetch', async (params, { rejectWithValue, getState }) => {
  try {
    const state = getState()
    const queryParams: Record<string, string | number> = {}

    if (params.date_from && params.date_to) {
      const startDate = new Date(params.date_from)
      const endDate = new Date(params.date_to)
      const { previousStart, previousEnd } = calculatePreviousPeriod(
        startDate,
        endDate,
      )

      Object.assign(queryParams, {
        date_from: formatDateToYYYYMMDD(previousStart),
        date_to: formatDateToYYYYMMDD(endDate),
      })
    }

    Object.assign(queryParams, {
      ...(params.funnel_name && { funnel_name: params.funnel_name }),
      ...(params.funnel_id && { funnel_id: params.funnel_id }),
      ...(params.date && { ad_date: params.date }),
    })

    let attributionEndPoint = 'api/sources/sales-funnel-activity/'

    const attributionSelected = ATTRIBUTION_SELECTION_OPTIONS.find(
      (opt) => opt.key === state.adsCrm.attributionSelection,
    )

    if (
      attributionSelected !== undefined &&
      attributionSelected?.apiEndPoint !== undefined &&
      attributionSelected?.apiEndPoint !== attributionEndPoint
    )
      attributionEndPoint = attributionSelected.apiEndPoint

    const companyId = `${state.profile.companyId}/`
    const response = await httpClient.get<ApiResponse>(
      attributionEndPoint + companyId,
      {
        params: queryParams,
      },
    )

    return response.data.data
  } catch (error) {
    console.error('Error fetching Ads CRM data:', error)
    return rejectWithValue('Failed to fetch Ads CRM data')
  }
})

const AdsCrmDataService = () => {
  return {
    fetchAdsCrmData,
  }
}

export default AdsCrmDataService()
