import { isSameDay } from 'date-fns'
import { Range, RangeKeyDict } from 'react-date-range'
import { ActivityAdsCrmData } from 'types/activityAdsCrmData.model'

// Convert a Date to a YYYY-MM-DD string in local time
export const formatDateToLocalISOString = (date: Date): string => {
  return date.toLocaleDateString('en-CA') // 'en-CA' locale uses YYYY-MM-DD format
}

// Convert a Date to a YYYY-MM-DD string in UTC
export const formatDateToUTC = (date: Date) => {
  return date.toISOString().split('T')[0]
}

// Convert a Date to a YYYY-MM-DD string
export const formatDateToYYYYMMDD = (date: Date): string => {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const calculateDateRange = (option: string): Range => {
  const today = new Date()
  today.setHours(0, 0, 0, 0) // Set to midnight in local time

  let startDate = new Date(today)
  let endDate = new Date(today)

  switch (option) {
    case 'today':
      break
    case 'yesterday':
      startDate.setDate(today.getDate() - 1)
      endDate = new Date(startDate)
      break
    case 'last_7_days':
      endDate.setDate(today.getDate() - 1)
      startDate.setDate(today.getDate() - 7)
      break
    case 'last_30_days':
      endDate.setDate(today.getDate() - 1)
      startDate.setDate(today.getDate() - 30)
      break
    case 'this_month':
      startDate = new Date(today.getFullYear(), today.getMonth(), 1)
      break
    default:
      break
  }

  return { startDate, endDate, key: 'selection' }
}

export const calculatePreviousPeriod = (
  date_from: Date,
  date_to: Date,
): { previousStart: Date; previousEnd: Date } => {
  const startDate = new Date(date_from)
  startDate.setHours(0, 0, 0, 0)

  const endDate = new Date(date_to)
  endDate.setHours(23, 59, 59, 999)

  const periodDuration = endDate.getTime() - startDate.getTime()
  const previousEnd = new Date(startDate.getTime() - 1)
  const previousStart = new Date(previousEnd.getTime() - periodDuration)

  return { previousStart, previousEnd }
}

export const formatTimestamp = (date: Date): string => {
  return date.toISOString().replace('T', ' ').slice(0, -1)
}

export const formatDate = (date: Date | undefined) => {
  return date
    ? date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    : 'Not set'
}

export const formatDateRange = (startDate: Date, endDate: Date): string => {
  if (startDate.getTime() === endDate.getTime()) {
    return formatDate(startDate)
  }
  return `${formatDate(startDate)} - ${formatDate(endDate)}`
}

export const getNumberOfDaysInRange = (dateRange: RangeKeyDict): number => {
  const startDate = dateRange.selection?.startDate
  const endDate = dateRange.selection?.endDate

  if (!startDate || !endDate) {
    console.error('Invalid date range provided')
    return 0
  }
  const diffInMs = endDate.getTime() - startDate.getTime()
  const numberOfDaysInRange = diffInMs / (1000 * 60 * 60 * 24) + 1
  return numberOfDaysInRange
}

export const filterDataByDate = (
  data: ActivityAdsCrmData[],
  targetDate: Date,
): ActivityAdsCrmData[] => {
  return data.filter((item) => {
    const itemDate = new Date(item.ad_date)
    return isSameDay(itemDate, targetDate)
  })
}
